export const mad = {
  state: {
    name: "libai",
    dialogFormVisible: false,
    sdxsalesdialog: false,
    orderDetailysde: true,
    dialogFormVisible1: false,
    pricesformdialogFormVisible: false,
    dataBmoney: "",
    orderdata: [],
    makeorderdata: "",
    contractId: "",
    rolecontroll: "",
    menuCode: [], //用于接受权限目录
    bandlogo: "", //品牌图片地址
    goodsdialogTableVisible: false, //商品页显示隐藏
    categorydialogTableVisible: false, //类目页显示隐藏
    branddialogTableVisible: false, //品牌页显示隐藏
    addgoodsdialogTableVisible: false, //赠品显示隐藏
    addgoodslist: [], //添加赠品
    goodslist: [], //选择促销商品
    categorylist: [], //选择促销类目
    brandslist: [], //选择促销品牌

    //   营销promotion包添加 strat
    choseDialog: false,
    storedialogTableVisible: false, //商品页显示隐藏
    reddialogTableVisible: false, //商品页显示隐藏
    retaildialogTableVisible: false, //分销商页显示隐藏
    sitedialogTableVisible: false,
    coupondialogTableVisible: false, //优惠券显示隐藏
    shoplist: [], //选择门店
    channellist: [], //选择渠道
    sltchannellist: [], //选择渠道
    shoppinglist: [], //选择渠道
    sltshoppinglist: [], //选择渠道
    sitelist: [], //选择渠道
    retaillist: [],
    sltsitelist: [], //选择渠道
    couponlist: [], //选择优惠券
    //   营销promotion包添加 end
    new_shoplist: [],
  },
  mutations: {
    addgoodslistchange: (state, paylod) => (state.addgoodslist = paylod), //添加赠品
    goodslistchange: (state, paylod) => (state.goodslist = paylod), //选择促销商品
    categorylistchange: (state, paylod) => (state.categorylist = paylod), //选择促销类目
    brandslistchange: (state, paylod) => (state.brandslist = paylod), //选择促销品牌

    mbt: (state, paylod) => (state.name = paylod),
    mdt: (state, paylod) => (state.dialogFormVisible = paylod),

    sdxmdt: (state, paylod) => (state.sdxsalesdialog = paylod),
    orderDetailysde: (state, paylod) => (state.orderDetailysde = paylod),
    mdt1: (state, paylod) => (state.dialogFormVisible = paylod),
    mpt: (state, paylod) => (state.pricesformdialogFormVisible = paylod),
    datamoney: (state, paylod) => (state.dataBmoney = paylod),
    addorderdata: (state, paylod) => (state.orderdata = paylod),
    makeorderdata: (state, paylod) => (state.makeorderdata = paylod),
    constId: (state, paylod) => (state.contractId = paylod),
    addrole: (state, paylod) => (state.rolecontroll = paylod),
    updatemenu: (state, paylod) => (state.menuCode = paylod),
    updatebandlogo: (state, paylod) => (state.bandlogo = paylod),
    goodschange: (state, paylod) => (state.goodsdialogTableVisible = paylod),
    categorychange: (state, paylod) => (state.categorydialogTableVisible = paylod),
    brandchange: (state, paylod) => (state.branddialogTableVisible = paylod),
    addgoods: (state, paylod) => (state.addgoodsdialogTableVisible = paylod),
    //   营销promotion包添加 strat
    storelistchange: (state, paylod) => (state.storelist = paylod), //选择分销商
    choseDialog: (state, paylod) => {
      state.choseDialog = paylod;
    },
    storechange: (state, paylod) => (state.storedialogTableVisible = paylod),
    redchange: (state, paylod) => (state.reddialogTableVisible = paylod),
    retailchange: (state, paylod) => (state.retaildialogTableVisible = paylod),
    sitechange: (state, paylod) => (state.sitedialogTableVisible = paylod),
    couponchange: (state, paylod) => (state.coupondialogTableVisible = paylod),
    shoplistchange: (state, paylod) => (state.shoplist = paylod), //选择促销商品
    channelList: (state, paylod) => (state.channellist = paylod),
    addChannelchange: (state, paylod) => (state.sltchannellist = paylod), //选择渠道
    shoppingList: (state, paylod) => (state.shoppinglist = paylod),
    addShoppingchange: (state, paylod) => (state.sltshoppinglist = paylod), //选择渠道
    siteList: (state, paylod) => (state.sitelist = paylod),
    retailllistchange: (state, paylod) => (state.retaillist = paylod), //选择分销商
    addSitechange: (state, paylod) => (state.sltsitelist = paylod), //选择渠道
    couponslistchange: (state, paylod) => (state.couponlist = paylod),

    new_shoplistchange: (state, paylod) => (state.new_shoplist = paylod),
    //   营销promotion包添加 end
  },
  actions: {
    had(state, paylod) {
      state.commit("mbt", paylod);
    },
  },
};
