import { app, user, menu, list, mad, products } from "./modules";
import getters from "./getters";

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  getters,
  modules: {
    app,
    user,
    menu,
    list,
    mad,
    products,
  },
});

export default store;
