import '#/iconfont/iconfont.css'
import '#/permission' // permission control
import '#/icons' // icon

/* globals Vue */

// import Vue from 'vue'
// import Vuex from 'vuex'
// import VueRouter from 'vue-router'
import ElementUI from 'element-ui'
import Clipboard from 'v-clipboard'
import App from './App'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import 'element-ui/lib/theme-chalk/index.css'

import router from './router'
import store from './store'
import xss from 'xss'
import moment from 'moment'
Vue.prototype.$moment = moment
Vue.prototype.$xss = xss
Vue.prototype.$loading = ElementUI.Loading

moment.locale('zh-cn')
// Vue.use(Vuex)
// Vue.use(VueRouter)
Vue.use(ElementUI)
Vue.use(Clipboard)

new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>',
})
