function getInitialState() {
  return {
    // 在 baseInfoForm 选择分类中获取的 pntreeCode
    pntreeCode: "",
    combinedForm: {
      tableData: "",
    },
    baseInfoForm: {
      // 平台分类 code list
      platformClassCodeList: "",
      // 平台分类名称（只传接口）
      platformClassName: "",
      // 商品品牌 code
      productsBrandCode: "",
      // 商品品牌名称（只传接口）
      productsBrandName: "",
      // 商品标题
      productsTitle: "",
      // 商品副标题
      productsSubtitle: "",
      // 商品编号
      productsNumber: "",
      // 重量/克
      productsWeight: 0,
      // 商品单位
      productsUnit: "",
      // 商品库存
      productsInventory: 1,
      // 起订量
      productsMinimumOrder: 1,
      // 商品图片
      productsImageList: [],
      // 商品视频
      productsVideoList: [],
    },
    infoForm: {
      // 商品参数
      productsParameterList: [],
      // 商品属性
      productsPropertiesList: [],
      // 商品信息 list - 属性和参数（只传接口）
      productsInfoList: [],
    },
    priceForm: {
      // 销售价
      salesPrice: 0,
      // 成本价
      costPrice: "",
      // 市场价
      marketPrice: "",
      // 前端显示
      frontEndDisplay: 1,
    },
    skuForm: {
      checkedList: [],
      tableData: [],
    },
    descEditor: {
      richText: "",
    },
    freightTemplateForm: {
      // 运费模板
      freightTemplate: "",
    },
    listingStatusForm: {
      // 上架状态
      listingStatus: 1,
    },
  };
}
export const products = {
  state: getInitialState(),
  mutations: {
    // PntreeCode
    updatePntreeCode(state, value) {
      state.pntreeCode = value;
    },
    // combinedForm
    combined_updateTableData(state, value) {
      state.combinedForm.tableData = value;
    },
    // baseInfoForm
    baseInfo_updatePlatformClassCodeList(state, value) {
      state.baseInfoForm.platformClassCodeList = value;
    },
    baseInfo_updatePlatformClassName(state, value) {
      state.baseInfoForm.platformClassName = value;
    },
    baseInfo_updateProductsBrandCode(state, value) {
      state.baseInfoForm.productsBrandCode = value;
    },
    baseInfo_updateProductsBrandName(state, value) {
      state.baseInfoForm.productsBrandName = value;
    },
    baseInfo_updateProductsTitle(state, value) {
      state.baseInfoForm.productsTitle = value;
    },
    baseInfo_updateProductsSubtitle(state, value) {
      state.baseInfoForm.productsSubtitle = value;
    },
    baseInfo_updateProductsNumber(state, value) {
      state.baseInfoForm.productsNumber = value;
    },
    baseInfo_updateProductsWeight(state, value) {
      state.baseInfoForm.productsWeight = value;
    },
    baseInfo_updateProductsUnit(state, value) {
      state.baseInfoForm.productsUnit = value;
    },
    baseInfo_updateProductsInventory(state, value) {
      state.baseInfoForm.productsInventory = value;
    },
    baseInfo_updateProductsMinimumOrder(state, value) {
      state.baseInfoForm.productsMinimumOrder = value;
    },
    baseInfo_updateProductsImageList(state, value) {
      state.baseInfoForm.productsImageList = value;
    },
    baseInfo_updateProductsVideoList(state, value) {
      state.baseInfoForm.productsVideoList = value;
    },
    // infoForm
    info_updateProductsParameterList(state, value) {
      state.infoForm.productsParameterList = value;
    },
    info_updateProductsParameterListItem(state, listItem) {
      Vue.set(
        state.infoForm.productsParameterList,
        listItem.index,
        listItem.value
      );
    },
    info_updateProductsPropertiesList(state, value) {
      state.infoForm.productsPropertiesList = value;
    },
    info_updateProductsPropertiesListItem(state, listItem) {
      Vue.set(
        state.infoForm.productsPropertiesList,
        listItem.index,
        listItem.value
      );
    },
    info_updateProductsInfoList(state, value) {
      state.infoForm.productsInfoList = value;
    },
    // priceForm
    price_updateSalesPrice(state, value) {
      state.priceForm.salesPrice = value;
    },
    price_updateCostPrice(state, value) {
      state.priceForm.costPrice = value;
    },
    price_updateMarketPrice(state, value) {
      state.priceForm.marketPrice = value;
    },
    price_updateFrontEndDisplay(state, value) {
      state.priceForm.frontEndDisplay = value;
    },
    // skuForm
    sku_updateCheckedList(state, value) {
      state.skuForm.checkedList = value;
    },
    sku_updateTableData(state, value) {
      state.skuForm.tableData = value;
    },
    sku_updateTableDataItem(state, listItem) {
      Vue.set(state.skuForm.tableData, listItem.index, listItem.value);
    },
    sku_updateTableDataItemProp(state, listItem) {
      Vue.set(
        state.skuForm.tableData[listItem.index],
        listItem.key,
        listItem.value
      );
    },
    // descEditor
    descEditor_updateRichText(state, value) {
      state.descEditor.richText = value;
    },
    // freightTemplateForm
    freightTemplate_updateFreightTemplate(state, value) {
      state.freightTemplateForm.freightTemplate = value;
    },
    // listingStatusForm
    listingStatus_updateListingStatus(state, value) {
      state.listingStatusForm.listingStatus = value;
    },
    // resetState
    resetState(state, value) {
      const initialState = getInitialState();
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
  },
};
