export const list = {
  state: {
    spReleaseShow: 0,
    eventS: {},
    data: [],
    goods: {},
    Names: "",
    relevanList: [],
    packageList: [],
    unitData: {},
    taocanList: [],
    saveObj: {}, // 保存状态下对象
    dataState: {},
    ExpCode: "",
    shopArrlist: [],
    listTwos: [],
    skudata: {},
    formdata: "",
    formdata1: "",
    priceNp: "",
    priceJf: "",
    priceMake: "",
    giftPri: "",
    priceAs: "",
    Skulist: [],
    arrlist: [],
    piclists: [],
    recommendList: [],
    goodsNames: "",
    goodsNo: "",
    video: "",
    checkeds: "",
    validate: true,
    picTypeList: [], // 商品图片分类
    temState: false, //模板默认的状态，判断是否需要更新数据
    saleUnit: "",
    goodsSupplyweight: "",
    pic: null,
    memberCode: "",
  },
  mutations: {
    goodsData: (state, obj) => {
      state.goods = obj;
    },
    goodsParms: (state, obj) => {
      state.eventS = obj;
    },
    goodsName: (state, obj) => {
      state.Names = obj;
    },
    getrelevanceList: (state, obj) => {
      state.relevanList = obj;
    },
    getrecommendList: (state, obj) => {
      state.recommendList = obj;
    },

    getPackageList: (state, obj) => {
      state.packageList = obj;
    },
    gettaocanList: (state, obj) => {
      state.taocanList = obj;
    },

    getDatestate: (state, obj) => {
      state.dataState = obj;
    },
    getUnitData: (state, obj) => {
      state.unitData = obj;
    },
    getDatestateObj: (state, obj) => {
      state.saveObj = obj;
    },
    getproId: (state, obj) => {
      state.ExpCode = obj;
    },
    getshopArr: (state, obj) => {
      state.shopArrlist = obj;
    },
    getlistTwo: (state, obj) => {
      state.listTwos = obj;
    },
    getskudata: (state, obj) => {
      state.skudata = obj;
    },
    getformData: (state, obj) => {
      state.formdata = obj;
    },
    getformData1: (state, obj) => {
      state.formdata1 = obj;
    },
    giftPrice: (state, obj) => {
      state.giftPri = obj;
    },
    getpriceNp: (state, obj) => {
      state.priceNp = obj;
    },
    getpriceMake: (state, obj) => {
      state.priceMake = obj;
    },
    getpriceAs: (state, obj) => {
      state.priceAs = obj;
    },
    getpriceJf: (state, obj) => {
      state.priceJf = obj;
    },
    getpricePref: (state, obj) => {
      state.pricePref = obj;
    },
    getpriceInside: (state, obj) => {
      state.priceInside = obj;
    },
    getpriceBase: (state, obj) => {
      state.priceBase = obj;
    },
    getSku: (state, obj) => {
      state.Skulist = obj;
    },
    getarrlist: (state, obj) => {
      state.arrlist = obj;
    },
    getdatapiclist: (state, obj) => {
      state.piclists = obj;
    },
    getgoodname: (state, obj) => {
      state.goodsNames = obj;
    },
    getgoodNo: (state, obj) => {
      state.goodsNo = obj;
    },
    getchecked: (state, obj) => {
      state.checkeds = obj;
    },
    setValidate: (state, obj) => {
      state.validate = obj;
    },
    setPicTypeList: (state, obj) => {
      state.picTypeList = obj;
    },
    setTemState: (state, obj) => {
      state.temState = obj;
    },
    getgoodsSupplyweight: (state, obj) => {
      state.goodsSupplyweight = obj;
    },
    getsaleUnit: (state, obj) => {
      state.saleUnit = obj;
    },
    goodslistchange: (state, paylod) => {
      state.goodslist = paylod; //选择商品
    },
    pic: (state, obj) => {
      state.pic = obj;
    },
    memberCode: (state, obj) => {
      state.memberCode = obj;
    },
  },
};