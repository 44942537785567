export const menu = {
  state: {
    items: [],
    isLoadRoutes: false,
    title: null,
    proappEnvLayout: {},
    firstLevelMenu: null,
    secondLevelMenu: null,
    thirdLevelMenu: null,
    currentMenu: "",
  },
  getters: {
    title: function(state) {
      state.title;
    },
    firstLevelMenu: function(state) {
      state.firstLevelMenu;
    },
    secondLevelMenu: function(state) {
      state.secondLevelMenu;
    },
    thirdLevelMenu: function(state) {
      state.thirdLevelMenu;
    },
    currentMenu: function(state) {
      return state.thirdLevelMenu;
    },
  },
  mutations: {
    EXPAND_MENU: function(state, menuItem) {
      if (menuItem.index > -1) {
        if (state.items[menuItem.index] && state.items[menuItem.index].meta) {
          state.items[menuItem.index].meta.expanded = menuItem.expanded;
        }
      } else if (menuItem.item && "expanded" in menuItem.item.meta) {
        menuItem.item.meta.expanded = menuItem.expanded;
      }
    },
    FIRSTLEVELMENU: function(state, data) {
      state.firstLevelMenu = data;
    },
    SET_CURRENT_MENU: function(state, data) {
      state.currentMenu = data;
    },
    SECONDlEVELMENU: function(state, data) {
      state.secondLevelMenu = data;
    },
    THIRDLEVELMENU: function(state, data) {
      state.thirdLevelMenu = data;
    },
    ADD_MENU: function(state, menuItems) {
      if (menuItems.length === 0) {
        state.items = [];
      } else {
        state.items = [...menuItems];
        // generateMenuItems(state.items, menuItems)
      }
    },
    LOAD_ROUTES: function(state) {
      state.isLoadRoutes = true;
    },
    MENUTITLE: function(state, data) {
      state.title = data;
    },
    setProappEnvLayout: function(state, data) {
      state.proappEnvLayout = data;
    },
  },
  actions: {
    addMenu: function({ commit }, menuItems) {
      if (menuItems.length > 0) {
        commit("LOAD_ROUTES");
        commit("ADD_MENU", menuItems);
      }
    },
    loadRoutes: function({ commit }) {
      commit("LOAD_ROUTES");
    },
  },
};
