import Cookies from "js-cookie";

export const app = {
  state: {
    sidebar: {
      opened: false,
      appNavOpen: false,
    },
  },
  mutations: {
    TOGGLE_SIDEBAR: function(state) {
      if (state.sidebar.opened) {
        Cookies.set("sidebarStatus", 1);
      } else {
        Cookies.set("sidebarStatus", 0);
      }
      state.sidebar.opened = !state.sidebar.opened;
    },
    TOGGLE_SIDEBAR_APPNAV: function(state) {
      state.sidebar.appNavOpen = !state.sidebar.appNavOpen;
    },
  },
  actions: {
    ToggleSideBar: function({ commit }, payload) {
      commit("TOGGLE_SIDEBAR");
    },
  },
};
