<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.app-main > div {
  padding: 0;
}

.t-box {
  display: flex;
}
.t-box-flex1 {
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
  box-flex: 1;
  width: auto;
}
.t-box-flex2 {
  -moz-box-flex: 2;
  -webkit-box-flex: 2;
  box-flex: 2;
  width: auto;
}
.t-box-flex3 {
  -moz-box-flex: 3;
  -webkit-box-flex: 3;
  box-flex: 3;
  width: auto;
}
.t-box-center {
  box-align: center;
}
.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.c-theme {
  color: #000 !important;
}

/*  .btn-inner{float: right;width: 78px;height:30px;font-size: 12px;margin: 8px;line-height: 6px;}
  .btn-outer{width:84px;height:38px;font-size: 14px;}
  .btn-del{background-color: rgb(229, 98, 34);}
  .last-table{border-right: 2px solid #eee;}*/
</style>
