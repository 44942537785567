import {
  login,
  logout,
  getInfo,
  getMenuList,
  getModulesList,
  checkPlat,
  getProappinfo,
  getPersonal,
  getUserIndex,
  getTopPerMenuListFPc,
} from "#/api/login";
import { getToken, setToken, removeToken } from "#/utils/auth";
import { deepTraversal } from "#/utils/index";

export const user = {
  state: {
    token: getToken(),
    isPlat: false,
    name: "",
    avatar: "",
    roles: [],
    appList: {
      name: "",
      list: [],
      memberRouter: [],
    }, // 应用列表
    proappEnvIndex: "",
    appInfo: {},
  },
  mutations: {
    SET_APP_INFO: function(state, appInfo) {
      state.appInfo = appInfo;
    },
    SET_ISPLAT: function(state, isPlat) {
      state.isPlat = isPlat;
    },
    SET_TOKEN: function(state, token) {
      state.token = token;
    },
    SET_NAME: function(state, name) {
      state.name = name;
    },
    SET_AVATAR: function(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function(state, roles) {
      state.roles = roles;
    },
    SET_URL: function(state, url) {
      state.proappEnvIndex = url;
    },
    SET_APP_LIST: function(state, list) {
      state.appList.list = list;
    },
    SET_APP_NAME: function(state, name) {
      state.appList.name = name;
    },
  },

  actions: {
    // 登录
    Login: function({ commit }, userInfo) {
      const username = userInfo.username.trim();
      commit("SET_NAME", username);
      return new Promise(function(resolve, reject) {
        login(username, userInfo.password)
          .then(function(response) {
            const data = response.data;
            resolve(response, data);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    //获取应用基本信息
    GetAppInfo: function({ commit }) {
      let proappEnvLayout = JSON.parse(
        window.sessionStorage.getItem("proappEnvLayout")
      );
      if (proappEnvLayout) {
        // console.log(proappEnvLayout, 'proappEnvLayoutproappEnvLayoutproappEnvLayoutproappEnvLayout')
        commit("setProappEnvLayout", proappEnvLayout);
        return proappEnvLayout;
      } else {
        return getProappinfo()
          .then(function(res) {
            commit("SET_APP_INFO", res);
            commit("setProappEnvLayout", res);
            window.sessionStorage.setItem(
              "proappEnvLayout",
              JSON.stringify(res)
            );
            return res;
          })
          .catch();
      }
    },
    GetAppRouter: function() {
      return getTopPerMenuListFPc().then(function(res) {
        if (res.menuList) {
          window.sessionStorage.setItem("menuList", JSON.stringify(res));
          if (!res) {
            Vue.prototype.$message.error("菜单路由没有权限");
            res = {};
          }
        }
        return res;
      });
    },

    // 获取用户信息
    GetInfo: function({ commit }) {
      return getInfo()
        .then(function(res) {
          if (res.success) {
            let data = res.dataObj;
            if (data) {
              commit("SET_NAME", data.userName);
              // getPersonal({ userId: data.userId }).then(val => {
              //   if (val) {
              //     window.sessionStorage.setItem('personalInfo', JSON.stringify(val))
              //   }
              // })
              getUserIndex().then((val) => {
                if (val.userInfo) {
                  window.sessionStorage.setItem(
                    "personalInfo",
                    JSON.stringify(val.userInfo)
                  );
                }
              });
            }
          }
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    // 获取当前用户信息
    GetUserInfo: function({ commit, state }) {
      return getUserIndex()
        .then(function(res) {
          const data = res.dataObj;
          commit("SET_NAME", data.userName);
        })
        .catch((error) => {});
    },

    // 登出 退出登录
    LogOut: function() {
      return logout()
        .then(function(resolve) {
          window.sessionStorage.clear();
          removeToken();
          resolve();
        })
        .catch(function(error) {});
    },

    // 前端 登出
    FedLogOut: function({ commit }) {
      return new Promise(function(resolve) {
        window.sessionStorage.clear();
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
    GetAppList: function({ commit }) {
      let appList = window.sessionStorage.getItem("appList");
      if (appList) {
        commit("SET_APP_LIST", JSON.parse(appList));
        commit("SET_APP_NAME", getAppName(JSON.parse(appList)));
      } else {
        // getModulesList()原方法
        getTopPerMenuListFPc()
          .then(function(res) {
            if ("success" in res && !res.success) {
              // console.log('未登录')
              return false;
            } else {
              window.sessionStorage.setItem("appList", JSON.stringify(res));
              // deepTraversal(res);
              commit("SET_APP_LIST", res);
              commit("SET_APP_NAME", getAppName(res));
            }
          })
          .catch();
      }
    },
    //根据权限，获取路由信息
    GetRouters: function(appCode) {
      return getMenuList("appPath=" + appCode)
        .then((res) => {
          return res;
        })
        .catch();
    },
    //判断是否是平台用户
    CheckIsPlat: function({ commit }) {
      return checkPlat()
        .then(function(res) {
          commit("SET_ISPLAT", res.success);
        })
        .catch();
    },
  },
};

function getAppName(list) {
  let name = "";
  if (list instanceof Array) {
    list.forEach(function(item) {
      if (item.menuJspath === process.env.APP_PATH) {
        name = item.menuName;
      }
    });
  }

  return name;
}
